import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

/// imge

// Components
import CardItem from "../../components/card-item/card-item";

//Actions
import { getAllCards } from "../../../store/actions/cardActions";

import { getInvitationsAction } from "../../../store/actions/MemberActions";

import { Link } from "react-router-dom";

import { Spinner, Table } from "react-bootstrap";

const CardPage = ({ props, history }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const getAllCard = useSelector((state) => state.getAllCard);
  const { cards, allCardLoading, allCardError } = getAllCard;

  //const { invitations} = getInvitations;
  const invitations = useSelector((state) => state.members.invitations);

  useEffect(() => {
    dispatch(getInvitationsAction({}));
    dispatch(getAllCards(search));
  }, [dispatch]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        dispatch(getAllCards(search));
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  useEffect(() => {
    if (cards) {
      console.log("cards founds");
      //toast.success("cards founds");
    }

    if (allCardLoading) {
      console.log("allCardLoading");
    } else {
      console.log("allCardLoading false");
    }

    if (allCardError) {
      console.log("allCardError");
      //toast.error(allCardError);
    }
  }, [cards, allCardLoading, allCardError]);

  const memberName = (cardId) => {
    const memberIndex = invitations.findIndex((x) => x.cardId === cardId);
    if (memberIndex != -1) {
      return invitations[memberIndex].email;
    } else {
      return "";
    }
  };

  function manageStatus(status) {
    if (status == "invite") {
      return "text-warning";
    } else if (status == "active") {
      return "text-success";
    } else {
      return "text-danger";
    }
  }

  return (
    <>
      <div class="row pb-5 mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Cards</h4>

              <div class="row">
                <div className="col-md-6">
                  <div className="input-group search-area d-lg-inline-flex">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (searchTimeout) {
                          clearTimeout(searchTimeout);
                        }
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </form>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              {!allCardLoading && cards ? (
                <>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>SN.</strong>
                        </th>
                        <th>
                          <strong>Name</strong>
                        </th>
                        <th>
                          <strong>Email</strong>
                        </th>
                        <th>
                          <strong>Taps</strong>
                        </th>
                        <th>
                          <strong>Unique Taps</strong>
                        </th>
                        <th>
                          <strong>Status</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {cards.map((card, i) => (
                        <tr key={i}>
                          <td>
                            <strong>{i + 1}</strong>
                          </td>
                          <td>
                            <Link to={"/card/" + card.id}>
                              <span className="text-black">
                                {card.activeProfile?.name || "-"}
                              </span>
                              <br />
                              <small>#{card.id}</small>
                            </Link>
                          </td>
                          <td>{card.orderId?.email || "-"}</td>
                          <td>{card?.taps || 0}</td>
                          <td>{card?.uniqueTaps || 0}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <i
                                className={
                                  "fa fa-circle " +
                                  manageStatus(card.status) +
                                  " mr-1"
                                }
                              ></i>{" "}
                              {card.status}
                            </div>
                          </td>

                          {/* <td>{card.taps || 0}</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardPage;
