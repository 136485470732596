import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
  loginAction2,
  loginFailedAction,
  signupAction,
  signupFailedAction,
} from "../../store/actions/AuthActions";

import swal from "sweetalert";
import { Spinner } from "react-bootstrap";

import axiosInstance from "../../services/AxiosInstance";

import { useAuth } from "../../providers/use-auth";

function Login(props) {
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //others
  const [companyName, setCompanyName] = useState("");
  const [industryType, setIndustryType] = useState("");

  //firebase
  const auth = useAuth();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(loginFailedAction(null));
  }, [dispatch]);

  const onLogin = async (e) => {
    //function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (companyName === "") {
        errorObj.companyName = "Company Name is Required";
        error = true;
      }
  
      if (industryType === "") {
        errorObj.industryType = "Industry Type is Required";
        error = true;
      }

    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));

    let response = await auth.signup(companyName, email, password);

    if (response.hasOwnProperty("message")) {
      console.log("sign up error");
      console.log(response.message);
      dispatch(loadingToggleAction(false));
      dispatch(signupFailedAction(response.message));
      //swal('Sign Up Error', response.message, "error");
      //toast.error(response.message);
      //setProgress(false);
      //props.showLoading = false;
      return;
    }

    if (response.hasOwnProperty("user")) {
      console.log(response.user);
      //toast.success("Sign Up Done!");
      //setProgress(false);
      //props.showLoading = false;
      const idToken = await response.user.getIdToken(true);
      dispatch(
        signupAction(
          idToken,
          companyName,
          industryType,
          email,
          password,
          props.history
        )
      );
      //dispatch(signUp(response.user));
      return;
    }
    

  };

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: "url('/api/media/background.svg')" }}
    >
      <div className="login-wrapper">
        <div className="login-aside-left">
          <div className="login-description">
            <div className="img-shadow">
              <img
                class="img img-fluid mt-0 image-shadow"
                width={350}
                src={process.env.REACT_APP_LOGO}
                alt=""
              />
            </div>

            <h2 className="main-title mb-2 mt-5">
              Welcome To {process.env.REACT_APP_NAME}
            </h2>
            <p className=""></p>
            <ul className="social-icons mt-4">
              <li>
                <a href={process.env.REACT_APP_URL}>
                  <i className="fa fa-globe"></i>
                </a>
              </li>
            </ul>
            <div className="mt-3 bottom-privacy">
              <p>
                Copyright © Designed & Developed by{" "}
                <a
                  href={process.env.REACT_APP_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {process.env.REACT_APP_BY}
                </a>{" "}
                2023
              </p>
            </div>
          </div>
        </div>
        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="px-5 py-4">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="">
                        <h3 className="dz-title mb-1">Sign up</h3>
                        <p className="">By entering information below &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
                      </div>
                      {props.errorMessage && (
                        <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                          {props.errorMessage}
                        </div>
                      )}
                      {props.successMessage && (
                        <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                          {props.successMessage}
                        </div>
                      )}

                      <form onSubmit={onLogin}>



                      <div className="form-group">
                          <label className="mb-0">
                            <strong>Company Name</strong>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setCompanyName(e.target.value)}
                            className="form-control"
                            placeholder="Company Name"
                            name="name"
                          />
                          {errors.companyName && (
                            <div className="text-danger fs-12">
                              {errors.companyName}
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <label className="mb-0">
                            <strong>Industry Type</strong>
                          </label>
                          <input
                            type="text"
                            onChange={(e) => setIndustryType(e.target.value)}
                            className="form-control"
                            placeholder="Industry Type"
                            name="industry_type"
                          />
                          {errors.industryType && (
                            <div className="text-danger fs-12">
                              {errors.industryType}
                            </div>
                          )}
                        </div>



                        <div className="form-group">
                          <label className="mb-0">
                            <strong>Mail Id</strong>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Type Your Email Address"
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-0">
                            <strong className="mr-auto">Password</strong>{" "}
                           
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            value={password}
                            placeholder="Type Your Password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && (
                            <div className="text-danger fs-12">
                              {errors.password}
                            </div>
                          )}
                        </div>

                        <input
                          type="hidden"
                          className="custom-control-input"
                          id="basic_checkbox_1"
                          value="true"
                        />

                        <div className="text-center mt-4">
                          {props.showLoading ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                            >
                              Sign Up
                            </button>
                          )}
                        </div>


                        <div className="new-account mt-2 text-center">
                        <p className="p">
                          already have an account?{" "}
                          <Link className="text-primary" to="./login">
                            Sign In
                          </Link>
                        </p>
                      </div>
                      

                        {props.errorMessage && (
                          <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2 text-danger">
                            {props.errorMessage}
                          </div>
                        )}
                        {props.successMessage && (
                          <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                            {props.successMessage}
                          </div>
                        )}
                      </form>

                      <div id="recaptcha-container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
