import React, { useState } from 'react'
/// React router dom
import {Switch, Route } from 'react-router-dom'
/// Css
import './index.css'
import './chart.css'
import './step.css'

/// Layout
import Nav from './layouts/nav'
import Footer from './layouts/Footer'

/// Dashboard
import Home from "./components/Dashboard/Home";
import MyProfile from "./components/Dashboard/MyProfile";
import Member from "./components/Dashboard/Member";
import Cards from "./components/Dashboard/Cards";
import Profiles from "./components/Dashboard/Profiles";

import CardAnalytics from "./components/Dashboard/CardAnalytics";

import AddProfile from "./pages/add-profile/add-profile";
import EditProfile from "./pages/edit-profile/edit-profile";



/// Pages
import Registration from './pages/Registration'
import Login from './pages/Login'
import ForgotPassword from './pages/ForgotPassword'


//Scroll To Top
import ScrollToTop from './layouts/ScrollToTop';


const Markup = () => {
  let path = window.location.pathname
  path = path.split('/')
  path = path[path.length - 1]
  let pagePath = path.split('-').includes('page')
  const [activeEvent, setActiveEvent] = useState(!path)

  const routes = [
    /// Dashboard
    { url: '', component: Home },
    { url: 'dashboard', component: Home },
    { url: "my-profile", component: MyProfile },
    { url: "members", component: Member },
    { url: "cards", component: Cards },
    { url: "card/:id", component: CardAnalytics },
    { url: "profiles", component: Profiles },
    { url: "add-profile", component: AddProfile },
    { url: "edit-profile/:id", component: EditProfile },
    { url: "login", component: Login },
    { url: "sign-up", component: Registration },
    { url: "forgot-password", component: ForgotPassword },


  ]

  return (
       <> 
          <div
            id={`${!pagePath ? 'main-wrapper' : ''}`}
            className={`${!pagePath ? 'show' : 'mh100vh'}`}
          >
            {!pagePath && (
              <Nav
                onClick={() => setActiveEvent(!activeEvent)}
                activeEvent={activeEvent}
                onClick2={() => setActiveEvent(false)}
                onClick3={() => setActiveEvent(true)}
              />
            )}
            <div
              className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
                !pagePath ? 'content-body' : ''
              }`}
            >
              <div
                className={`${!pagePath ? 'container-fluid' : ''}`}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Switch>
                  {routes.map((data, i) => (
                    <Route
                      key={i}
                      exact
                      path={`/${data.url}`}
                      component={data.component}
                    />
                  ))}
                </Switch>
              </div>
            </div>
            {!pagePath && <Footer />}
          </div>
         <ScrollToTop />
       </>
  )
}

export default Markup
