import { lazy, Suspense, useEffect } from "react";
/// Components
import Index from "./jsx/index";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";



/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import "./css/custom.css";

//firebase auth
import { ProvideAuth, analytics } from "./providers/use-auth.js";

const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword.js"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});
function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch, props.history);
  }, [dispatch, props.history]);

  useEffect(() => {
    if (window.self !== window.top) {
      // The site is inside an iframe (Clickjacking attack possible)
      document.body.innerHTML = ''; // Hide the page content
    }
  }, []);

   useEffect(() => {
      const preventActions = (event) => {
        if (event.key === "F12"|| (event.ctrlKey && event.shiftKey && event.key === "I")) {
          event.preventDefault();
        }
      };
      const disableRightClick = (event) => {
        event.preventDefault();
      };
  
      window.addEventListener("keydown", preventActions);
      window.addEventListener("contextmenu", disableRightClick);
  
      return () => {
        window.removeEventListener("keydown", preventActions);
        window.removeEventListener("contextmenu", disableRightClick);
      };
    }, []);

  let routes = (
    <ProvideAuth>
      {
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/sign-up" component={SignUp} />
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch>
      }
    </ProvideAuth>
  );
  if (props.isAuthenticated) {
    return (
      <>
       
            <Suspense
              fallback={
                <div id="preloader">
                  <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                  </div>
                </div>
              }
            >
              <Index />
            </Suspense>
         
      </>
    );
  } else {
    return (
      <div className="vh-100">
        
            <Suspense
              fallback={
                <div id="preloader">
                  <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                  </div>
                </div>
              }
            >
              {routes}
            </Suspense>
         
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
