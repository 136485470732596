import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Menu
import MetisMenu from "metismenujs";

///
 
class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      // this.mm("dispose");
      // console.log(this.mm);
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");

      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }

      btn.addEventListener("click", toggleFunc);   
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];

      /// Active menu
      let deshBoard = [
             "",
          
         ];
      
      return (
         <div className="deznav">
            <PerfectScrollbar className="deznav-scroll">
               <MM className="metismenu" id="menu">



               <li className={`${path === "" ? "mm-active" : ""}`}>
            <Link to="/" className="ai-icon">
              <i className="flaticon-381-home-2"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
          </li>


          <li className={`${path === "members" ? "mm-active" : ""}`}>
              <Link to="/members" className="ai-icon">
                <i className="flaticon-381-user-7"></i>
                <span className="nav-text">Members</span>
              </Link>
            </li>

            <li className={`${path === "cards" ? "mm-active" : ""}`}>
              <Link to="/cards" className="ai-icon">
                <i className="flaticon-381-id-card-1"></i>
                <span className="nav-text">Cards</span>
              </Link>
            </li>

            <li className={`${path === "profiles" ? "mm-active" : ""}`}>
              <Link to="/profiles" className="ai-icon">
                <i className="flaticon-381-id-card-5"></i>
                <span className="nav-text">Profiles</span>
              </Link>
            </li>


                
					
               </MM>
              

               <div className="copyright">
          <p>
            <strong>Product By:</strong>
          </p>
          <p>{process.env.REACT_APP_BY}</p>
        </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default SideBar;
