export const isAuthenticated = (state) => {
    //if (state.auth.auth.idToken) return true;
    console.log("isAuthenticated - "+JSON.stringify(state.auth));
    if (state.auth.auth.token){

        if(state.auth.auth.user.status){
            if(state.auth.auth.user.status == "active"){
                console.log("isAuthenticated - active");
                return true;
            }else{
                console.log("isAuthenticated - not active");
                return false
            }
        }else{
            console.log("isAuthenticated - true");
            return true;
        }

        //return true;
    }else{
        console.log("isAuthenticated - false");
        return false;
    }
};
