import React, { useState, useEffect } from "react";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




import { useDispatch } from "react-redux";

import axiosInstance from '../../../services/AxiosInstance';

import {
   getProfile,
} from '../../../store/actions/AuthActions';







const Home = () => {

   const dispatch = useDispatch();

   const [statistics, setStatistics] = useState(null);

   useEffect(() => {
		dispatch(getProfile());
      loadStatistics();
	  }, [dispatch]);



   async function loadStatistics(){
		
      try {
         
         const { data } = await axiosInstance.get(
              "corporate/statistics"
         );
   

     console.log("statistics data - "+JSON.stringify(data));
   
      if(data.status === false){
         
      }else{
         setStatistics(data);
      }
      
      }catch (error) {
         
   
      }
   
     }
   


   function SampleNextArrow(props) {
      const { onClick } = props;
      return (
         <div className="owl-next" onClick={onClick} style={{ zIndex: 99 }}>
            <i className="fa fa-caret-right" />
         </div>
      );
   }

   function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
         <div
            className="owl-prev disabled"
            onClick={onClick}
            style={{ zIndex: 99 }}
         >
            <i className="fa fa-caret-left" />
         </div>
      );
   }

   const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
         {
            breakpoint: 1599,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            },
         },
         {
            breakpoint: 990,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
            },
         },
      ],
   };
   return (
      <>
         <div className="row">
            <div className="col-xl col-md-6">
               <div className="card bordered-card">
                  <div className="card-body p-4">
                    
                     <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.sent:0}</h2> 
                        <span className="circle-1 bg-primary mt-4" />
                     </div>
                     
                     <span className="fs-14">Invite Sent</span>

                  </div>
               </div>
            </div>
            <div className="col-xl col-md-6 col-sm-6">
               <div className="card bordered-card">
                  <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.accepted:0}</h2> 
                        <span className="circle-1 bg-success mt-4" />
                     </div>
                     
                     <span className="fs-14">Invite Accepted</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
               <div className="card bordered-card">
               <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.rejected:0}</h2> 
                        <span className="circle-1 bg-danger mt-4" />
                     </div>
                     
                     <span className="fs-14">Invite Rejected</span>
                  </div>
               </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
               <div className="card bordered-card">
               <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                        <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">{statistics?statistics.cancelled:0}</h2> 
                        <span className="circle-1 bg-danger mt-4" />
                     </div>
                     
                     <span className="fs-14">Invite Cancelled</span>
                  </div>
               </div>
            </div>

          

         
         </div>
      </>
   );
};

export default Home;
