import axiosInstance from '../services/AxiosInstance';

export function getInvitations(filter) {
    return axiosInstance.post(`corporate/invitations`,filter);
}

export function inviteMember(postData) {
    return axiosInstance.post(`corporate/invite`, postData);
}

export function bulkInviteMember(postData) {
    return axiosInstance.post(`corporate/bulk-invite`, postData);
}

export function cancelInvitation(postData) {
    return axiosInstance.post(`corporate/cancel-invitation`, postData);
}

export function formatInvitations(inviteData) {
    let invitations = [];
    for (let key in inviteData) {
        invitations.push({ ...inviteData[key], id: key });
    }

    return invitations;
}
