import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";

import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Table, Modal, Button, Spinner } from "react-bootstrap";

import profileTypeList from "../../../utils/profile-type";

// Components

//Actions
import { getAllProfiles } from "../../../store/actions/cardActions";

import axiosInstance from "../../../services/AxiosInstance";

import { getInvitationsAction } from "../../../store/actions/MemberActions";

const ProfilePage = ({ props, history }) => {
  const dispatch = useDispatch();

  const [search, setSearch] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);

  const [postModal, setPostModal] = useState(false);

  const [members, setMembers] = useState([]);
  const [checkAllMember, setCheckAllMember] = useState(false);

  const invitations = useSelector((state) => state.members.invitations);

  useEffect(() => {
    dispatch(getInvitationsAction({ status: "accepted" }));
    //load profiles
    dispatch(getAllProfiles(search));
  }, [dispatch]);

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        dispatch(getAllProfiles(search));
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [search]);

  function profileData(profileType) {
    const profileTypeIndex = profileTypeList.findIndex(
      (x) => x.type === profileType
    );
    if (profileTypeIndex != -1) {
      return profileTypeList[profileTypeIndex];
    }
  }

  //get all profiles
  const allProfilesSelector = useSelector((state) => state.getAllProfile);
  const { allProfileLoading, allProfileError, profiles } = allProfilesSelector;

  const [activeProfileID, setActiveProfileID] = useState("");

  async function onDelete(profileId) {
    try {
      const { data } = await axiosInstance.post("profiles/remove", {
        profile_id: profileId,
      });

      console.log("delete data - " + JSON.stringify(data));

      if (data.success === false) {
        //toast.error(data.message);
        swal("Delete Profile", data.message, "error");
      } else {
        //toast.success(data.message);
        swal("Delete Profile", data.message, "success");
        //window.location.reload();

        setTimeout(() => {
          dispatch(getAllProfiles(search));
        }, 500);
      }
    } catch (error) {}
  }

  async function onEdit(profileId) {
    console.log("onEdit -" + profileId);
    history.push({ pathname: "/edit-profile/" + profileId, state: {} });
  }

  function onProfileChange(profile) {
    //show modal to select members

    console.log(JSON.stringify(profile));

    setActiveProfileID(profile.id);
    console.log("onProfileChange - " + profile.id);

    //add existing member

    // const dummy = [];
    // profile.members.map((m)=>{
    //   dummy.push(m);
    // });

    setMembers(profile.members);

    setPostModal(true);
  }

  async function onActiveProfile() {
    try {
      const { data } = await axiosInstance.post("corporate/active-profile", {
        is_all: checkAllMember,
        members: members,
        profile_id: activeProfileID,
      });

      console.log("active profile data - " + JSON.stringify(data));

      if (data.success === false) {
        swal("Active Profile", data.message, "error");
      } else {
        swal("Active Profile", data.message, "success");

        setPostModal(false);
      }
    } catch (error) {}
  }

  async function onShareProfile() {
    try {
      const { data } = await axiosInstance.post("corporate/share-profile", {
        is_all: checkAllMember,
        members: members,
        profile_id: activeProfileID,
      });

      console.log("share profile data - " + JSON.stringify(data));

      if (data.success === false) {
        swal("Share Profile", data.message, "error");
      } else {
        swal("Share Profile", data.message, "success");

        setPostModal(false);
        dispatch(getAllProfiles(search));
      }
    } catch (error) {}
  }

  function onMemberCheck(memberId, checked) {
    console.log("onMemberCheck - " + checked);

    const dummy = [...members];

    if (checked) {
      dummy.push(memberId);
      setMembers(dummy);
      setCheckAllMember(false);
    } else {
      const memberIndex = dummy.findIndex((x) => x === memberId);

      if (memberIndex != -1) {
        dummy.splice(memberIndex, 1);
      } else {
        //dummy.push(memberId);
      }

      setMembers(dummy);
      setCheckAllMember(false);
    }
  }

  function onAllMemberCheck(checked) {
    console.log("onAllMemberCheck - " + checked);

    if (checked) {
      let dummy = [];
      for (let key in invitations) {
        dummy.push(invitations[key]._id);
      }

      setMembers(dummy);
    } else {
      setMembers([]);
    }

    setCheckAllMember(checked);
  }

  return (
    <>
      <Modal
        
        show={postModal}
        onHide={setPostModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Select Members - {invitations.length}</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setPostModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="checkAll"
                      required=""
                      checked={checkAllMember}
                      onChange={(e) => onAllMemberCheck(e.target.checked)}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="checkAll"
                    ></label>
                  </div>
                </th>
                <th>
                  <strong>NAME</strong>
                </th>
                <th>
                  <strong>Email</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              {invitations.map((invitation, i) => (
                <tr key={i}>
                  <td>
                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id={"checkbox-" + invitation._id}
                        checked={members.includes(invitation._id)}
                        onChange={(e) =>
                          onMemberCheck(invitation._id, e.target.checked)
                        }
                        required=""
                      />
                      <label
                        className="custom-control-label"
                        htmlFor={"checkbox-" + invitation._id}
                      ></label>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex align-items-center">
                      {""}
                      <span className="w-space-no">{invitation.name}</span>
                    </div>
                  </td>
                  <td>{invitation.email} </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger light" onClick={() => setPostModal(false)}>
            Close
          </Button>
          <Button
            variant=""
            type="button"
            className="btn btn-primary"
            onClick={(e) => onShareProfile()}
          >
            Share Profile
          </Button>
        </Modal.Footer>
      </Modal>

      <div class="row pb-5 mb-4">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title mr-auto">Profiles</h4>

              <div class="row">
                <div className="col-md-6">
                  <div className="input-group search-area d-lg-inline-flex">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (searchTimeout) {
                          clearTimeout(searchTimeout);
                        }
                      }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here"
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </form>
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <svg
                          width={20}
                          height={20}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M23.7871 22.7761L17.9548 16.9437C19.5193 15.145 20.4665 12.7982 20.4665 10.2333C20.4665 4.58714 15.8741 0 10.2333 0C4.58714 0 0 4.59246 0 10.2333C0 15.8741 4.59246 20.4665 10.2333 20.4665C12.7982 20.4665 15.145 19.5193 16.9437 17.9548L22.7761 23.7871C22.9144 23.9255 23.1007 24 23.2816 24C23.4625 24 23.6488 23.9308 23.7871 23.7871C24.0639 23.5104 24.0639 23.0528 23.7871 22.7761ZM1.43149 10.2333C1.43149 5.38004 5.38004 1.43681 10.2279 1.43681C15.0812 1.43681 19.0244 5.38537 19.0244 10.2333C19.0244 15.0812 15.0812 19.035 10.2279 19.035C5.38004 19.035 1.43149 15.0865 1.43149 10.2333Z"
                            fill="#A4A4A4"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 p-2 text-right">
                  <Link
                    className="btn btn-sm btn-primary font-w600 mr-2"
                    to="/add-profile"
                  >
                    Add Profile
                  </Link>
                </div>
              </div>
            </div>

            <div className="card-body">
              {allProfileLoading ? (
                <div className="text-center mt-4">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : profiles.length === 0 ? (
                <>
                  <div class="title-text my-4">
                    <h3>No Profile Found</h3>

                    <p>Add your first card profile</p>
                  </div>
                </>
              ) : (
                <div className="row">
                  {profiles.map((profile, i) => (
                    <Col lg={4} md={4} sm={12} key={i} className="mt-4 mt-md-0">
                      <figure class="profile-caption-1 card-input bg-gradient-1">
                        <img
                          src={
                            i == 0 || i % 2 == 0
                              ? "/api/media/profile-bg-2.png"
                              : "/api/media/profile-bg-1.png" /* profileData(profile.type).background */
                          }
                          alt=""
                          class="w-100 card-img-top rounded effect-2"
                        />

                        <figcaption class="px-4 text-left">
                          <h2
                            className={
                              "h5 font-weight-bold mb-0 " +
                              (i == 0 || i % 2 == 0
                                ? "text-grey"
                                : "text-white")
                            }
                          >
                            <i class={profileData(profile.type).icon}></i>
                            &nbsp; {profileData(profile.type).name}
                          </h2>
                          <p
                            className={
                              "text-small marquee " +
                              (i == 0 || i % 2 == 0
                                ? "text-grey"
                                : "text-white")
                            }
                          >
                            <span>
                              {profile.type === "business"
                                ? profile.name
                                : profile.link}
                            </span>
                          </p>
                        </figcaption>

                        <div class="jto-top-profile-item">
                          <h6
                            className={
                              "h6 font-weight-bold mb-0 " +
                              (i == 0 || i % 2 == 0
                                ? "text-grey"
                                : "text-white")
                            }
                          >
                            {profile.profileName ? profile.profileName : ""}
                          </h6>
                        </div>
                      </figure>

                      <Row className="mt-2 mb-4">
                        <Col>
                          <div class="float-left">
                            <span class="check-icon"></span>

                            <Button
                              onClick={(e) => onProfileChange(profile)}
                              className="btn btn-sm btn-success mr-2"
                            >
                              <i class=""></i> Share
                            </Button>
                          </div>

                          <div class="float-right">
                            <Link
                              to={{
                                pathname: "/edit-profile/" + profile.id,

                                state: {},
                              }}
                              className="btn btn-sm btn-primary mr-2"
                            >
                              Edit
                            </Link>

                            <Link
                              onClick={(e) => onDelete(profile.id)}
                              className="btn btn-sm btn-danger light mr-2"
                            >
                              <i class="ti-trash"></i>
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
