const profileTypeList = [
    {
      type: "business",
      name: "Business Card",
      icon: "fa fa-id-card-o",
      hint:"",
      background:"images/profile-bg-1.png",
    },
    {
      type: "spotify",
      name: "Spotify Card",
      icon: "fa fa-spotify",
      hint:"@spotify",
      inputType:"Username",
      background:"https://justtaponce.s3.ap-south-1.amazonaws.com/images/bg-spotify.jpg"
    },
    {
      type: "instagram",
      name: "Instagram",
      icon: "ti-instagram",
      hint:"@instagram",
      inputType:"Username",
      background:"https://justtaponce.s3.ap-south-1.amazonaws.com/images/bg-instagram.jpg"
    },
    {
      type: "portfolio",
      name: "Portfolio Card",
      icon: "fa fa-picture-o",
      hint:"https://...",
      inputType:"Link",
      background:"https://justtaponce.s3.ap-south-1.amazonaws.com/images/bg-portfolio.jpg"
    },
    {
      type: "linkedin",
      name: "LinkedIn Card",
      icon: "ti-linkedin",
      hint:"@linkedIn",
      inputType:"Username",
      background:"https://justtaponce.s3.ap-south-1.amazonaws.com/images/bg-linkedin.jpg"
    },
    {
      type: "youtube",
      name: "YouTube Card",
      icon: "ti-youtube",
      hint:"@youtube",
      inputType:"Username",
      background:"https://justtaponce.s3.ap-south-1.amazonaws.com/images/bg-youtube.jpg"
    },
  ];

  export default profileTypeList