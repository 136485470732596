import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  Row,
  Col,
  Table,
  Modal,
  Button,
  Spinner,
  Dropdown,
} from "react-bootstrap";

import {
  getAllProfiles,
  getCardDetails,
} from "../../../store/actions/cardActions";

import ReactApexChart from "react-apexcharts";

import axiosInstance from "../../../services/AxiosInstance";

import swal from "sweetalert";

import profileTypeList from "../../../utils/profile-type";



import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

import { exportToExcel } from "react-json-to-excel";

const CardAnalyticsPage = ({ match, props, history }) => {
  const dispatch = useDispatch();

  const [qr, setQR] = useState("");
  const [copied, setCopied] = useState(false);

  const cardDetailsSelector = useSelector((state) => state.getCardDetails);
  const { cardLoading, cardError, card } = cardDetailsSelector;
  const [activeProfileID, setActiveProfileID] = useState("");

  //const { invitations} = getInvitations;

  const [filter, setFilter] = useState("daily");

  const [loadingData, setLoadingData] = useState(true);
  const [options, setOptions] = useState({});
  const [series, setSeries] = useState({});

  const [totalTaps, setTotalTaps] = useState(0);
  const [totalUniqueTaps, setTotalUniqueTaps] = useState(0);
  const [todaysTaps, setTodaysTaps] = useState({});

  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    console.log("card Id - " + match.params.id);
    dispatch(getCardDetails(match.params.id));
    loadProfiles();
    getQR(match.params.id);
  }, dispatch);

  const getQR = async (cardId) => {
    const queryParams = new URLSearchParams({
      url: "https://profile.tap1ce.uk/" + cardId,
      size: 150,
      color: "black",
      bg_color: "transparent",
    });
    const url = "/api/app/generate-qr?" + queryParams.toString();
    setQR(url);

     };

  async function downloadQR() {
    const { data } = await axiosInstance.get(
      "cards/generate-qr/" + match.params.id,
      {}
    );

    if (data.status) {
      console.log("base64 - " + data.b64);
      const linkSource = data.b64;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = "tap1ce-qr.png";
      downloadLink.click();
    }
  }

  function shareUrl() {
    if (card) {
      if (card.username) {
        return "https://profile.tap1ce.uk/" + card.username;
      }
    }
    return "https://profile.tap1ce.uk/" + match.params.id;
  }

  useEffect(() => {
    if (card) {
      //load profiles
      onChangeFilter(filter);

      if (card.activeProfileId != null) {
        setActiveProfileID(card.activeProfileId);
      }
    }
  }, [card, cardLoading]);

  const loadProfiles = async () => {
    try {
      const { data } = await axiosInstance.post("profiles/corporate", {
        card_id: match.params.id,
      });

      if (data.status) {
        setProfiles(data.profiles);
      }
    } catch (e) {}
  };

  async function onChangeFilter(filter) {
    setLoadingData(true);
    setFilter(filter);

    try {
      const { data } = await axiosInstance.post("cards/statistic", {
        card_id: match.params.id,
        filter: filter,
      });

      console.log("filter data - " + JSON.stringify(data));

      if (data.status) {
        setTodaysTaps(data.todayResult);

        var totalTaps = 0;
        data.result.map((e) => {
          totalTaps = totalTaps + e.taps;
        });

        var totalUTaps = 0;
        data.result.map((e) => {
          totalUTaps = totalUTaps + e.uniqueTaps;
        });

        setTotalTaps(totalTaps);
        setTotalUniqueTaps(totalUTaps);

        setSeries([
          {
            name: "Taps",
            data: data.result.map((e) => e.taps),
          },
          {
            name: "Uniue Taps",
            data: data.result.map((e) => e.uniqueTaps),
          },
        ]);

        setOptions({
          chart: {
            height: 350,
            type: "line",
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },

          stroke: {
            width: [4, 4],
            colors: ["#6cc51d", "#1EA7C5"],
            curve: "straight",
          },
          legend: {
            show: false,
          },
          xaxis: {
            type: "text",
            categories: data.result.map((e) => e._id),
          },
          colors: ["#6cc51d", "#1EA7C5", "#FF9432"],

          markers: {
            size: [8, 8, 6],
            strokeWidth: [0, 0, 4],
            strokeColors: ["#6cc51d", "#1EA7C5", "#FF9432"],
            border: 0,
            colors: ["#6cc51d", "#1EA7C5", "#fff"],
            hover: {
              size: 10,
            },
          },
          yaxis: {
            title: {
              text: "",
            },
          },
        });

        setLoadingData(false);
      } else {
        setLoadingData(false);
      }
    } catch (error) {
      setLoadingData(false);
    }
  }

  function profileData(profileType) {
    const profileTypeIndex = profileTypeList.findIndex(
      (x) => x.type === profileType
    );
    if (profileTypeIndex != -1) {
      return profileTypeList[profileTypeIndex];
    }
  }

  async function onDelete(profileId) {
    try {
      const { data } = await axiosInstance.post("profiles/remove", {
        profile_id: profileId,
      });

      console.log("delete data - " + JSON.stringify(data));

      if (data.success === false) {
        //toast.error(data.message);
        swal("Delete Profile", data.message, "error");
      } else {
        //toast.success(data.message);
        swal("Delete Profile", data.message, "success");
        //window.location.reload();

        setTimeout(() => {
          dispatch(loadProfiles());
        }, 500);
      }
    } catch (error) {}
  }

  const onProfileChange = async (profile_id) => {
    //onActiveProfile(profile);

    try {
      const { data } = await axiosInstance.post("profiles/active", {
        card_id: match.params.id,
        profile_id: profile_id,
      });

      console.log("active profile data - " + JSON.stringify(data));

      if (data.status === false) {
        swal("Active Profile", data.message, "error");
      } else {
        swal("Active Profile", data.message, "success");
        setActiveProfileID(profile_id);
      }
    } catch (error) {}
  };

  const onConnectionExport = async () => {
    //exportToExcel
    const { data } = await axiosInstance.post("corporate/connections", {
      card_id: match.params.id,
    });
    if (data.status) {
      const connections = data.connections.map((x, i) => {
        return {
          NO: i + 1,
          NAME: x.name,
          PHONE_NO: x.phone,
          EMAIL: x.email,
          COMPANY: x.company,
          ABOUT: x.about,
          DATE: moment(new Date(x.createdAt)).format("DD-MM-YYYY hh:mm A"),
        };
      });

      exportToExcel(
        connections,
        `MY CONNECTIONS - ${moment().format("DD-MM-YYYY h:mm")}`
      );
    } else {
      swal("My Connections", data.message, "error");
    }
  };

  return (
    <>
      {cardLoading || !card ? (
        <div class="row pb-5 mb-4">
          <div>Loading card Details...</div>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-xl col-md-6">
              <div className="card bordered-card">
                <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                    <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                      {card.taps ?? 0}
                    </h2>
                    <span className="circle-1 bg-primary mt-4" />
                  </div>

                  <span className="fs-14">Total Taps</span>
                </div>
              </div>
            </div>
            <div className="col-xl col-md-6 col-sm-6">
              <div className="card bordered-card">
                <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                    <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                      {card.uniqueTaps ?? 0}
                    </h2>
                    <span className="circle-1 bg-success mt-4" />
                  </div>

                  <span className="fs-14">Total Unique Taps</span>
                </div>
              </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
              <div className="card bordered-card">
                <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                    <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                      {todaysTaps ? todaysTaps.taps ?? 0 : 0}
                    </h2>
                    <span className="circle-1 bg-primary mt-4" />
                  </div>

                  <span className="fs-14">Today's Taps</span>
                </div>
              </div>
            </div>
            <div className="col-xl col-md-4 col-sm-6">
              <div className="card bordered-card">
                <div className="card-body p-4">
                  <div className="d-flex flex-wrap align-items-center text-head">
                    <h2 className="fs-24 text-black font-w600 mb-0 mr-auto card-bold-title">
                      {todaysTaps ? todaysTaps.uniqueTaps ?? 0 : 0}
                    </h2>
                    <span className="circle-1 bg-success mt-4" />
                  </div>

                  <span className="fs-14">Today's Unique Taps </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header flex-wrap pb-0 border-0">
                  <div className="mr-auto pr-3 mb-2">
                    <h4 className="text-black fs-20">Card Statistic</h4>
                    <p className="fs-13 mb-2 mb-sm-0 text-black">
                      Total Taps &amp; Unique Taps of card
                    </p>
                  </div>
                  <div className="d-flex mr-3 mr-sm-5 mb-2">
                    <div>
                      <h4 className="fs-18 text-black font-w600 mb-0">
                        {totalTaps}
                      </h4>
                      <span className="fs-12 text-black">Taps</span>
                    </div>
                  </div>
                  <div className="d-flex mr-3 mr-sm-5 mb-2">
                    <div>
                      <h4 className="fs-18 text-black font-w600 mb-0">
                        {totalUniqueTaps}
                      </h4>
                      <span className="fs-12 text-black">Unique Taps</span>
                    </div>
                  </div>

                  <Dropdown className="dropdown mt-sm-0 mt-3 mb-0">
                    <Dropdown.Toggle
                      variant=""
                      as="button"
                      className="btn rounded border border-light dropdown-toggle"
                    >
                      {filter}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-right">
                      <Dropdown.Item onClick={(e) => onChangeFilter("daily")}>
                        Daily
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => onChangeFilter("monthly")}>
                        Monthly
                      </Dropdown.Item>
                      <Dropdown.Item onClick={(e) => onChangeFilter("yearly")}>
                        Yearly
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-3">
                  {loadingData ? (
                    <div className="text-center my-2">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <div id="chart">
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="line"
                        height={380}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-12 col-xxl-12">
              <div className="card">
                <div className="card-header flex-wrap pb-0 border-0">
                  <div className="mr-auto pr-3 mb-2">
                    <h4 className="text-black fs-20">Card Profiles</h4>
                  </div>
                </div>

                <div className="card-body">
                  <div className="row mb-4">
                    <div className="col-4">
                      {qr != "" && (
                        <div className="text-center">
                          <div
                            className=""
                            href="#"
                            onClick={(e) => downloadQR()}
                            target="_blank"
                            download
                          >
                            <img className="img img-thumbnail" src={qr}></img>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="col-4 my-2 text-center">
                      <div className="row">
                        <div className="col-6 my-2 text-center">
                          <CopyToClipboard
                            text={shareUrl()}
                            onCopy={() => {
                              setCopied(true);
                              setTimeout(() => {
                                setCopied(false);
                              }, 1500);
                            }}
                          >
                            <div className="text-center">
                              <button className="btn btn-sm btn-light mr-2">
                                Copy link
                              </button>
                              {copied ? (
                                <span className="mt-2" style={{ color: "red" }}>
                                  Copied.
                                </span>
                              ) : (
                                ""
                              )}
                            </div>
                          </CopyToClipboard>
                        </div>

                        <div className="col-6 my-2 text-center">
                          <Button
                            className="btn btn-sm btn-primary"
                            onClick={(e) => onConnectionExport()}
                          >
                            My Connections
                          </Button>
                        </div>
                      </div>
                    </div>

                    <div className="col-4 text-center">
                      <div className="row">
                        <div className="col-md-4 col-4 my-2">
                          <EmailShareButton
                            url={shareUrl()}
                            body={""}
                            subject={"Tap1ce Profile"}
                          >
                            <EmailIcon size={32} round />
                          </EmailShareButton>
                        </div>

                        <div className="col-md-4 col-4 my-2">
                          {" "}
                          <FacebookShareButton
                            url={shareUrl()}
                            hashtag="#tap1ce"
                          >
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                        </div>
                        <div className="col-md-4 col-4 my-2">
                          <LinkedinShareButton
                            url={shareUrl()}
                            title={"Tap1ce Profile"}
                          >
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                        </div>
                        <div className="col-md-4 col-4 my-2">
                          <TelegramShareButton
                            url={shareUrl()}
                            title={"Tap1ce Profile"}
                          >
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                        </div>
                        <div className="col-md-4 col-4 my-2">
                          <TwitterShareButton
                            url={shareUrl()}
                            title={"Tap1ce Profile"}
                          >
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                        </div>
                        <div className="col-md-4 col-4 my-2">
                          <WhatsappShareButton
                            url={shareUrl()}
                            title={"Tap1ce Profile"}
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    {profiles.map((profile, i) => (
                      <Col
                        lg={4}
                        md={4}
                        sm={12}
                        key={i}
                        className="mt-4 mt-md-0"
                      >
                        <figure class="profile-caption-1 card-input bg-gradient-1">
                          <img
                            src={
                              i == 0 || i % 2 == 0
                                ? "https://dashboard.tap1ce.uk/api/media/profile-bg-2.png"
                                : "https://dashboard.tap1ce.uk/api/media/profile-bg-1.png"
                            }
                            alt=""
                            class="w-100 card-img-top rounded effect-2"
                          />

                          <figcaption class="px-4 text-left">
                            <h2
                              className={
                                "h5 font-weight-bold mb-0 " +
                                (i == 0 || i % 2 == 0
                                  ? "text-grey"
                                  : "text-white")
                              }
                            >
                              <i class={profileData(profile.type).icon}></i>
                              &nbsp; {profileData(profile.type).name}
                            </h2>
                            <p
                              className={
                                "text-small marquee " +
                                (i == 0 || i % 2 == 0
                                  ? "text-grey"
                                  : "text-white")
                              }
                            >
                              <span>
                                {profile.type === "business"
                                  ? profile.name
                                  : profile.link}
                              </span>
                            </p>
                          </figcaption>

                          <div class="jto-top-profile-item">
                            <h6
                              className={
                                "h6 font-weight-bold mb-0 " +
                                (i == 0 || i % 2 == 0
                                  ? "text-grey"
                                  : "text-white")
                              }
                            >
                              {profile.profileName ? profile.profileName : ""}
                            </h6>
                          </div>
                        </figure>

                        <Row className="mt-2 mb-4">
                          <Col>
                            <div class="float-left">
                              <span class="check-icon"></span>

                              {/* <Button
                              onClick={(e) => onProfileChange(profile)}
                              className="btn btn-sm btn-success mr-2"
                            >
                              <i class=""></i> Share
                            </Button> */}

                              {activeProfileID === profile.id ? (
                                <Button className="btn btn-sm btn-block btn-success">
                                  Active Now
                                </Button>
                              ) : (
                                <Button
                                  onClick={(e) => onProfileChange(profile.id)}
                                  className="btn btn-sm btn-primary btn-block btn-make-active"
                                >
                                  Activate
                                </Button>
                              )}
                            </div>

                            <div class="float-right">
                              <Link
                                to={{
                                  pathname: "/edit-profile/" + profile.id,

                                  state: {},
                                }}
                                className="btn btn-sm btn-primary mr-2"
                              >
                                Edit
                              </Link>

                              <Link
                                onClick={(e) => onDelete(profile.id)}
                                className="btn btn-sm btn-danger light mr-2"
                              >
                                <i class="ti-trash"></i>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CardAnalyticsPage;
